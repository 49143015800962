@import '~antd/dist/antd.css';
.App {}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=“number”] {
  -moz-appearance: textfield;
}

.logoicon {
  height: 32px;
  margin: 16px;
  cursor: pointer;
  background-image: url('./static/img/lALPD3IrpSS1HsEgzKg_168_32.png');
}

.site-layout-background .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.site-layout-background .trigger:hover {
  color: #1890ff;
}

.site-layout-background .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.search_box {
  margin-bottom: 10px;
  line-height: 3;
}

.search_box .ant-input-affix-wrapper, .search_box .ant-select {
  width: 150px;
  margin-right: 10px;
  border-radius: 5px 5px 5px 5px;
}

.add_list {
  padding: 10px;
  line-height: 3;
}

.add_list .ant-input-affix-wrapper, .add_list .ant-select {
  width: 150px;
  margin-right: 10px;
  border-radius: 5px 5px 5px 5px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}