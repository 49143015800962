.flex_box {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.left_box {
  width: 20%;
  border-right: #ccc 1px solid;
  overflow: auto;
}

/*定义滚动条高宽及背景
 高宽分别对应横竖滚动条的尺寸*/

.left_box::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #F5F5F5;
}

/*定义滚动条轨道
  内阴影+圆角*/

.left_box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-color: #F5F5F5;
}

/*定义滑块
  内阴影+圆角*/

.left_box::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

.right_box {
  width: 80%;
  overflow: auto;
  padding: 10px;
}

.employee_list {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.employee_list:hover {
  background: #eee;
  cursor: pointer;
}